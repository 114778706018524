@import 'styles/variables';

.date-range-calendar {
  background: #ffffff;
  border: 1px solid #e8e8eb;
  width: 650px;
  z-index: 4;
  position: absolute;
  top: 140%;
  right: 0%;
  @media (max-width: $BREAKPOINT_MD) {
    position: absolute;
    top: 140%;
    right: 16px;
  }
  @media (max-width: 740px) {
    position: absolute;
    top: 140%;
    right: -16px;
  }
  .rdrMonthAndYearWrapper {
    font-size: 14px;
    font-weight: 600;
    .rdrNextPrevButton {
      background-color: transparent;
    }
  }

  .rdrMonth {
    padding-bottom: 8px;
    .rdrMonthName {
      text-align: center;
      color: black;
      font-size: 14px;
      font-weight: 600;
      padding-bottom: 15px;
      padding-top: 15px;
    }
    .rdrWeekDays {
      .rdrWeekDay {
        color: black;
        font-size: 12px;
        font-weight: 700;
        width: 40px;
        height: 40px;
      }
    }
  }

  .rdrDay {
    height: 40px;
  }

  .rdrDayNumber {
    font-size: 12px;
    font-weight: 400;
    height: 100%;
    span {
      height: 100%;
      width: 100%;
    }
    width: 100%;
    top: 0px;
  }
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    //eliminazione bordo quando vai sopra
    border: 0px;
    background-color: rgba(107, 193, 180, 0.1);
    height: 100%;
    width: 100%;
    border-radius: 0px;
    top: 0px;
    bottom: 0px;
  }
  .rdrDayHovered {
    background-color: rgba(107, 193, 180, 0.1);
    top: 0px;
    bottom: 0px;
  }
  .rdrDayPassive {
    visibility: hidden;
  }
  .rdrStartEdge,
  .rdrEndEdge {
    background-color: #6bc1b4;
    border: 0px;
    border-radius: 0px;
    height: 100%;
    width: 100%;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
  .rdrInRange {
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border: 0px;
    border-radius: 0px;
    background-color: rgba(107, 193, 180, 0.1);
    height: 100%;
    width: 100%;
  }
  .rdrInRange ~ .rdrDayNumber span {
    //seleziona ogni rdrDayNumber che viene preceduto da un rdrInRange
    color: black !important;
  }
  .rdrDayToday .rdrDayNumber span {
    color: #009688;
    font-weight: 700;
  }
  .rdrDayToday .rdrDayNumber span:after {
    background: transparent;
  }
  .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after {
    background: transparent;
  }
}

.date-range-button {
  margin-right: 16px !important;
  border-radius: 0px !important;
  text-transform: none !important;
  color: #989898 !important;
  font-size: 14px !important;
  font-weight: 500;
  width: 80px !important;
  height: 35px !important;
  padding: 0px !important;
  justify-content: start !important;
}
.date-range-button-selected {
  margin-right: 16px !important;
  padding: 0px !important;
  border-radius: 0px !important;
  text-transform: none !important;
  color: #6bc1b4 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  text-decoration: underline !important;
  width: 80px !important;
  height: 35px !important;
  justify-content: start !important;
}
.date-range-button-selected:hover {
  background-color: white !important;
}
.date-range-button:hover {
  color: #6bc1b4 !important;
  text-decoration: underline !important;
  font-weight: 700 !important;
  background-color: white !important;
}

.open-calendar-button {
  padding: 0px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  border-radius: 0px !important;
  border: 1px solid #e9e9e9 !important;
  width: 200px !important;
  height: 35px !important;
}

.open-calendar-button:hover {
  border: 1px solid #6bc1b4 !important;
  background-color: white !important;
}

.open-calendar-button-focus {
  padding: 0px !important;
  font-size: 14px;
  font-weight: 600 !important;
  border-radius: 0px !important;
  border: 1px solid #6bc1b4 !important;
  width: 200px !important;
  height: 35px !important;
  background-color: white !important;
}
.open-calendar-button-focus:hover {
  background-color: white !important;
}

.position-of-tuning-date-range {
  position: absolute;
  top: 0%;
  right: 150px;
  display: flex;
  @media (max-width: $BREAKPOINT_MD) {
    position: relative;
    right: 0;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    position: relative;
    padding-left: 16px;
  }
}

.customPrevButtonClassName i{
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.customNextButtonClassName i{
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(-45deg);
}
