@import "styles/colors.scss";
@import "styles/variables.scss";
@import "styles/main.scss";

.labelPie {
  margin-left: 30px;
}

.donut-legend-item {
  margin-right: 10px;
  margin-bottom: 20px;
}

.donut-legend-icon {
  @extend .circle;
  margin: 5px 8px 5px 5px !important;
}

.tooltip-legend {
  max-width: 11.625rem;
  .MuiTooltip-tooltip {
    font-size: 0.75rem;
  }
}

.border-bottom {
  border-bottom: 1px solid $BACKGROUNDS;
}

.light-font {
  color: $LIGHT_FONT_COLOR !important;
}

.line-visibility {
  opacity: 0.3;
}

.recharts-legend-wrapper {
  top: 82%;
  .legend-items {
    margin-top: 10px;
    justify-content: space-around;
    vertical-align: middle;
  }
}

.donut-legend-stats {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 10px;
  align-items: baseline;
  .donut-legend-percentual-stats {
    font-size: 15px;
    font-weight: 600;
    width: 45px;
    display: flex;
    justify-content: flex-end;
  }
}

.recharts-tooltip-wrapper {
  z-index: 2;
  overflow-wrap: break-word !important;
  .custom-tooltip {
    white-space: initial;
    position: relative;
    background: rgba(30, 32, 31, 0.6);
    border-color: transparent;
    border-radius: 4px;
    height: 40px;
    width: 100px;
    overflow-wrap: break-word;
    height: auto;
    text-align: center;
    .recharts-tooltip-label {
      margin: 0;
      padding: 0;
      color: white;
      font-size: 12px;
    }
  }
  .custom-tooltip::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-right-color: rgba(30, 32, 31, 0.6);
    border-left: 0;
    margin-top: -5px;
    margin-left: -5px;
  }
  .custom-tooltip-bottom {
    white-space: initial;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background: rgba(30, 32, 31, 0.6);
    border-color: transparent;
    border-radius: 4px;
    height: 35px !important;
    width: 130px !important;
    overflow-wrap: break-word;
    height: auto;
    text-align: center;
    &.line-chart {
      transform: translate(-48px, -24px);
      width: 65px !important;
      padding: 0px 10px;
    }
    .recharts-tooltip-label {
      margin: 0;
      padding: 0;
      color: white;
      font-size: 12px;
    }
  }
  .custom-tooltip-bottom::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: rgba(30, 32, 31, 0.6);
    border-bottom: 0;
    margin-left: -5px;
    margin-bottom: -5px;
  }
}

.table-cell {
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    text-align: center;
    justify-content: space-around;
  }
  .table-write {
    display: flex;
    flex-flow: column;
    @media (min-width: $BREAKPOINT_SMARTPHONE) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.horizontal-bar-label {
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  font-size: 14px;

  .horizontal-bar-label-name {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .horizontal-bar-stats {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 7px;
    .horizontal-bar-percentual-stat {
      font-weight: 900;
      padding-left: 5px;
    }
  }
}
.horizontal-bar-label-small {
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  font-size: 10px;

  .horizontal-bar-label-name {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .horizontal-bar-stats {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 7px;
    .horizontal-bar-percentual-stat {
      font-weight: 900;
      padding-left: 5px;
    }
  }
}

.recharts-responsive-container {
  height: 100%;
}
.doughnut-chart-container {
  @media (max-width: $BREAKPOINT_MD) {
    padding-bottom: 30px;
  }
}
