@import 'styles/colors';
@import 'styles/variables';

.avatar-wrapper {
  height: 100% !important;

  padding: 0px !important;
  // width: 50px;
  color: white;
  .user-button {
    border-radius: 0px;
    width: 100%;
    height: 100% !important;
    padding: 0px !important;
    min-width: 50px;
    @media (min-width: $BREAKPOINT_SMARTPHONE) {
      border-left: 1px solid white;
    }

    border-left: 0px;

    span {
      height: 100%;
      div {
        height: 100%;
        width: 50px;
        svg {
          padding: 13px;
        }
      }
    }
  }
}
.no-border {
  border: 0px !important;
}

.logged-user-name {
  color: white;
  text-transform: capitalize;
  width: 100px !important;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.user-option-selection{
  width:150px!important;
  .MuiMenuItem-root{
    padding-left: 12px!important;
    height: 48px;
    font-size: 14px!important;
  }
}
