@import "styles/colors";
@import "styles/variables";
@import "styles/fontfaces";

body {
  font-family: $DEFAULT_FONT;
  font-weight: $REGULAR;
  color: $DEFAULT_FONT_COLOR;
  margin: 0px !important;
  background-color: $BACKGROUND_PAGE;
  padding: 0 !important;
}

.default-font {
  font-family: $DEFAULT_FONT;
}

.full-height {
  height: 100%;
}

.small-font-6 {
  font-size: 6px;
}

.small-font-8 {
  font-size: 8px;
}

.small-font-9 {
  font-size: 9px;
}

.small-font-10 {
  font-size: 10px;
}

.small-font-12 {
  font-size: 12px;
}

.small-font-14 {
  font-size: 14px;
}
.small-font-15 {
  font-size: 15px;
}
.small-font-16 {
  font-size: 18px;
}

.elements-spacing {
  margin: 10px;
}

.MT-0 {
  margin-top: 0px;
}

.MT-10 {
  margin-top: 10px;
}

.MR-10 {
  margin-right: 10px;
}

.ML-10 {
  margin-left: 10px;
}

.MR-30 {
  @media (min-width: $BREAKPOINT_MD) {
    margin-right: 30px;
  }
}

.width-100 {
  width: 100%;
}

.full-width {
  @extend .width-100;
  width: -webkit-fill-available;
  width: -moz-available;
}
.tirth-width {
  width: 30%;
}
.half-width {
  width: 50%;
}
.number-content {
  font-weight: 600;
  font-size: 16px;
  padding-top: 5px;
}
.elements-spacing-graph {
  margin-left: 10px;
  margin-right: 10px;
}
.legend-content {
  display: flex;
  flex-flow: row wrap;
}

.legend-line {
  margin-top: 7px;
  display: flex;
}

.outer-title {
  margin-top: 40px;
}
.MuiInputBase-root {
  font-size: 0.9rem !important;
}
.custom-tooltip {
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
}
.noborder {
  box-shadow: none !important;
  margin-left: 15px;
  margin-right: 0px !important;
  margin-top: 15px !important;
  margin-bottom: 12px !important;
}
.flex {
  display: flex;
}
.first-card {
  height: 95% !important;
}
.flex-column {
  @extend .flex;
  flex-direction: column;
}
.flex-row {
  @extend .flex;
  flex-direction: row;
}

.flex-column-justified {
  @extend .flex-column;
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around;
}
.flex-centered {
  @extend .flex;
  align-items: center;
}

.flex-centered-between {
  @extend .flex-centered;
  justify-content: space-between;
}

.flex-centered-between-wrapped {
  @extend .flex-centered-between;
  flex-wrap: wrap;
}

.mobile-flex-column {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    flex-direction: column;
  }
}

.flex-wrap-container {
  @extend .flex;
  flex-wrap: wrap;
}

.flex-container-end {
  @extend .flex;
  justify-content: flex-end;
}
.flex-container-start {
  @extend .flex;
  justify-content: flex-start;
}

.flex-row-3 {
  flex-basis: 30%;
}
.flex-1 {
  flex: 1;
}

.input-text {
  margin: 10px 5px 20px 5px !important;
  background-color: white;
}

.image-size {
  object-fit: scale-down;
  object-position: center center;
  max-width: 100%;
  max-height: 400px;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.light {
  @extend .default-font;
  font-weight: $LIGHT !important;
}

.regular {
  @extend .default-font;
  font-weight: $REGULAR !important;
}

.medium {
  @extend .default-font;
  font-weight: $MEDIUM !important;
}

.semi-bold {
  @extend .default-font;
  font-weight: $SEMI_BOLD !important;
}

.bold {
  @extend .default-font;
  font-weight: $BOLD !important;
}

.black {
  @extend .default-font;
  font-weight: $BLACK !important;
}

.unselected {
  color: $DISABLED !important;
}

.selected {
  color: $DEFAULT_TEXT !important;
}

.backdrop-color {
  background-color: $BACKGROUNDS;
}

.backdrop-transparent {
  background-color: $BACKGROUNDS_TRANSPARENT;
}

.default-background {
  background-color: $BACKGROUNDS;
}

.more-z {
  z-index: 101 !important;
}

.color-white {
  color: white !important;
  fill: white !important;
}

.libero {
  @extend .color-white;
  background-color: $VERDE !important;
}

.interesse {
  @extend .color-white;
  background-color: $AZZURRO !important;
}

.proposta {
  @extend .color-white;
  background-color: $ARANCIO !important;
}

.compromesso {
  @extend .color-white;
  background-color: $ROSSO !important;
}

.rogitato {
  @extend .color-white;
  background-color: $VIOLA !important;
}

.opzionato {
  @extend .color-white;
  background-color: $GIALLO !important;
}

.search-button {
  @extend .color-white;
  background-color: $SEARCH_BUTTON !important;
  padding: 10px !important;
}

.modify-button {
  @extend .search-button;
}

.add-button {
  @extend .search-button;
}

.remove-button {
  @extend .color-white;
  padding: 10px !important;
  background-color: $EDITING_COLOR !important;
}

.icons-in-button {
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 18px !important;
  padding: 0px !important;
}

.pointer {
  cursor: pointer;
}

.grab {
  cursor: grab;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 20px;
}

.user-background {
  background-color: $USER_BACKGROUND !important;
}

.smaller-font {
  font-size: $SMALL_LABEL_FONT;
}

.flex-grow-1 {
  flex-grow: 1;
}

.modal-custom-container {
  padding: 5px 10px;
  margin: 20px 15px 20px 0px;
  border-radius: 30px !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin: 0;
  }
}

.add-appartments-modal-content {
  min-height: 200px;
  min-width: 400px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    min-width: unset;
  }
}

.custom-listbox {
  background-color: $SELECT_BACKGROUND;
  color: $USER_DATA_COLOR;
}

.list-divider {
  border-bottom: 1px solid $USER_DATA_COLOR;
}

.loader-wrapper {
  min-height: 60px;
}

.absolute-position {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.absolute-position-relative-content-loader {
  position: relative;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.relative {
  position: relative;
}

.snackbar-body {
  color: $COLOR_SECONDARY;
  font-weight: $MEDIUM;
  background: $COLOR_PRIMARY;
  border-radius: 25px;
  text-align: center;
  padding: 10px;
  min-width: 100px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.16);
}

.snackbar-body-error {
  color: $COLOR_SECONDARY;
  font-weight: $MEDIUM;
  background: $ERROR_COLOR;
  border-radius: 25px;
  text-align: center;
  padding: 10px;
  min-width: 100px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.16);
}

.magnified-container {
  background-color: white;
  cursor: zoom-in !important;
}

.zoom-options {
  align-self: normal;
  margin-right: 5px;
}

.image-zoom-container {
  width: fit-content;
}

.image-transformcomponent-container {
  border: 2px solid black;
}

.zoom-button {
  background: $COLOR_PRIMARY;
  color: $COLOR_SECONDARY;
}

.ellipsis {
  text-overflow: ellipsis;
}

.justify-center {
  justify-content: center;
}

.MuiSnackbarContent-message {
  color: white !important;
}

//SEZIONE MIA

.page-title {
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 600;
  color: #989898;
  margin-top: 80px;
  margin-bottom: 24px;
  margin-left: 16px;
}

.border-right-line {
  @media (min-width: $BREAKPOINT_SMARTPHONE) {
    border-right: 1px solid #e8e9eb;
  }
}

.padding-top-lg {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding-top: 50px;
  }
}

.table-cell-grid-bottom {
  height: 113px;
  border-bottom: 1px solid #e8e9eb;
}
.table-cell-grid-right {
  height: 113px;
  border-right: 1px solid #e8e9eb;
}

.placeholder-components {
  color: #989898;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
  padding-top: 70px;
  padding-bottom: 10px;
}
.border-grid {
  @media (min-width: $BREAKPOINT_MD) {
    height: 377px;
  }
  padding: 16px;
  @media (max-width: $BREAKPOINT_MD) {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

.bottom-card {
  padding: 16px;
  @media (max-width: $BREAKPOINT_MD) {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

.anchor-link {
  color: #6bc1b4;
  text-decoration: none;
  border-bottom: 2px solid #6bc1b4;
}
.body-wrapper-open {
  position: relative;
  transition: margin 1s;
  @media (min-width: $BREAKPOINT_SMARTPHONE) {
    margin-top: $DEFAULT_MARGIN;
    margin-right: 16px;
    margin-bottom: 10px;
    margin-left: 216px;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-bottom: 8px;
  }
}
.body-wrapper-close {
  position: relative;
  transition: margin 1s;
  @media (min-width: $BREAKPOINT_SMARTPHONE) {
    margin-top: $DEFAULT_MARGIN;
    margin-right: 16px;
    margin-bottom: 10px;
    margin-left: 72px;
  }
}
.title-padding {
  padding-bottom: 14px;
}

.padding-right-5 {
  padding-right: 5px;
}

.hide-component {
  display: none;
}

.screenshot-button {
  position: absolute !important;
  top: 0;
  right: 14px;
  border: 1px solid #ebe9eb !important;
  text-transform: none !important;
  font-size: 12px !important;
  border-radius: 0px;
  width: 120px;
  height: 35px;
  color: #989898 !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    min-width: 36px !important;
    width: 36px !important;
  }
}
.screenshot-button:hover {
  color: #6bc1b4 !important;
  background-color: white !important;
  border: 1px solid #6bc1b4 !important;
}

.main-container.no-shadow-4-screenshot * {
  box-shadow: none;
}

.body-wrapper-open {
  position: relative;
  transition: margin 1s;
  @media (min-width: $BREAKPOINT_SMARTPHONE) {
    margin-top: $DEFAULT_MARGIN;
    margin-right: 16px;
    margin-bottom: 10px;
    margin-left: 216px;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-bottom: 8px;
  }
}
.body-wrapper-close {
  position: relative;
  transition: margin 1s;
  @media (min-width: $BREAKPOINT_SMARTPHONE) {
    margin-top: $DEFAULT_MARGIN;
    margin-right: 16px;
    margin-bottom: 10px;
    margin-left: 72px;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-bottom: 8px;
  }
}
.delete-margin {
  margin: 0 !important;
}
